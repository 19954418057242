<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ this.id ? "编辑商品" : "新增商品" }}</h3>
    <el-tabs v-model="active" :before-leave="beforeLeave">
      <el-tab-pane label="基本信息" name="1">
        <createOne ref="createOne" @onload="onload" />
        <div class="flex align-items justify-content-center marginTop20">
          <!-- <el-button size="medium" type="primary" @click="nextStep('1')"
            >下一步</el-button
          > -->
          <el-button
            size="medium"
            :disabled="submitType"
            type="primary"
            @click="handleSubmit"
            >提交</el-button
          >
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="规格信息" name="2">
        <createTwo ref="createTwo" />
        <div class="flex align-items justify-content-center marginTop20">
          <el-button size="medium" @click="previousStep('1')">上一步</el-button>
          <el-button
            size="medium"
            :disabled="submitType"
            type="primary"
            @click="handleSubmit"
            >提交</el-button
          >
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import createOne from "./components/createOne.vue";
import createTwo from "./components/createTwo.vue";
import createThree from "./components/createThree.vue";
import { add, update, view } from "@/api/supplierGoods/goods";
import { goodsStoreAdd, goodsStoreUp, storeView } from "@/api/goods/goods";
export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree,
  },
  data() {
    return {
      active: "1",
      id: this.$route.query.id, //门店的商品id
      goodsId: this.$route.query.goodsId, //平台商品库的id
      formOne: {},
      formTwo: {},
      formThree: {},
      submitType: false, // 避免重复点击
      storeId: this.$store.state.user.userInfo.storeId,
    };
  },
  mounted() {},
  methods: {
    async onload() {
      if (this.id) {
        //编辑门店商品
        await this.getGoodsView();
      } else {
        if (this.goodsId) {
          await this.getView();
        }
      }
    },
    previousStep(e) {
      this.active = "1";
    },
    async nextStep(stop) {
      if (stop === "1") {
        const dataOne = await this.$refs.createOne.submitForm();
        return new Promise((resolve, reject) => {
          if (dataOne) {
            this.active = "2";
            resolve();
          } else {
            reject();
          }
        });
      }
      if (stop === "2") {
        const dataTwo = await this.$refs.createTwo.submitForm();
        return new Promise((resolve, reject) => {
          if (dataTwo) {
            const { specsData, specsList } = dataTwo;
            const spec = specsList.map((item) => {
              return {
                id: item.id,
                childIds: item.checkList,
              };
            });
            this.formTwo = {
              specDetail: specsData,
              spec,
            };
            // console.log('this.formTwo',this.formTwo);
            resolve();
          } else {
            reject();
          }
        });
      }
    },
    async beforeLeave(route, stop) {
      if (stop === "1") {
        const dataOne = await this.$refs.createOne.submitForm();
        return new Promise((resolve, reject) => {
          if (dataOne) {
            // console.log('dataOne',dataOne);
            // this.$refs.createTwo.listSpecId(dataOne.supplierId);
            const { ...rest } = dataOne;
            this.formOne = {
              ...rest,
            };
            resolve();
          } else {
            reject();
          }
        });
      } else if (stop === "2") {
        if (route === "1") {
          const dataOne = await this.$refs.createOne.submitForm();
          return new Promise((resolve, reject) => {
            if (dataOne) {
              resolve();
            } else {
              reject();
            }
          });
        } else {
          const dataTwo = await this.$refs.createTwo.submitForm();
          return new Promise((resolve, reject) => {
            if (dataTwo) {
              const { specsData, specsList } = dataTwo;
              const spec = specsList.map((item) => {
                return {
                  id: item.id,
                  childIds: item.checkList,
                };
              });
              this.formTwo = {
                specDetail: specsData,
                spec,
              };
              // console.log('this.formTwo',this.formTwo);
              resolve();
            } else {
              reject();
            }
          });
        }
      }
    },
    async handleSubmit() {
      const dataOne = await this.$refs.createOne.submitForm();
      if (!dataOne) {
        return;
      } else {
        this.formOne = dataOne;
      }

      let list = this.formOne.classifyIds.map((element) => {
        return element[2];
      });
      if(this.formOne.relevanceIdList.length>10){
        this.$message.error("最多只能选择10个商品");
        return
      }
  
      if (this.id) {
        let obj = {
          ...this.formOne,
          // skuList: createTwo.specsData,
          storeId: this.storeId,
          goodsId: this.goodsId,
          id: this.id,
          classifyIds: list.join(","),
          // limitBuyLeastNumMin: this.formOne.limitBuyLeastNumMin || 0,
          // limitBuyLeastNumMax: this.formOne.limitBuyLeastNumMax || 0,
          // saleNum: this.formOne.saleNum || 0,
          // limitBuyLeastDept: this.formOne.limitBuyLeastDept&&this.formOne.limitBuyLeastDept.length>0?this.formOne.limitBuyLeastDept : null,
        };
        const { data } = await goodsStoreUp(obj);
        this.$message.success("修改成功");
        this.$router.go(-1);
      } else {
        let obj = {
          ...this.formOne,
          // skuList: createTwo.specsData,
          storeId: this.storeId,
          goodsId: this.goodsId,
          classifyIds: list.join(","),
          // limitBuyLeastNumMin: this.formOne.limitBuyLeastNumMin || 0,
          // limitBuyLeastNumMax: this.formOne.limitBuyLeastNumMax || 0,
          // saleNum: this.formOne.saleNum || 0,
          // limitBuyLeastDept: this.formOne.limitBuyLeastDept&&this.formOne.limitBuyLeastDept.length>0?this.formOne.limitBuyLeastDept : null,
        };
        const { data } = await goodsStoreAdd(obj);
        this.$message.success("新增成功");
        this.$router.go(-1);
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
    async getGoodsView() {
      const { data } = await storeView({
        id: this.id,
        type: 2,
      });

      this.formOne = data;
      this.$refs.createOne.setForm({
        ...this.formOne,
        classifyIds: data.classifyIdList,
      });
    },
    async getView() {
      const { data } = await view(this.goodsId);
      this.formOne = {
        sort: data.sort,
      };
      this.$refs.createOne.setForm(this.formOne);
    },
  },
};
</script>

<style scoped></style>
