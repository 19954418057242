<template>
  <div>
    <el-form :model="form" ref="form" label-width="150px">
      <div style="margin-bottom: 10px">批量设置</div>

      <el-table style="margin-bottom: 20px" :data="settingData">
        <el-table-column label="*折扣方式" prop="discountType" width="200">
          <template #default="scope">
            <el-form-item label-width="0px">
              <el-select
                size="small"
                v-model="scope.row.discountType"
                placeholder="请选择折扣方式"
                @change="settingValue($event, 'discountType')"
              >
                <el-option
                  v-for="item in discountList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

   <el-table-column label="*折扣率" prop="discountRatio" width="200">
          <template #default="scope">
            <el-form-item label-width="0px">
              <el-input
                :disabled="scope.row.discountType == 1 ? false : true"
                v-model="scope.row.discountRatio"
                placeholder="请输入折扣率"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @input="
                  settingValue($event, 'discountRatio', scope.$index, scope.row)
                "
                type="text"
                clearable
                :max="1"
                :min="0.01"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*售价" prop="salePrice" width="200">
          <template #default="scope">
            <el-form-item label-width="0px">
              <el-input
                :disabled="scope.row.discountType == 1 ? false : true"
                v-model="scope.row.salePrice"
                placeholder="请输入售价"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @input="settingValue($event, 'salePrice')"
                type="text"
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*排序" prop="sort" width="200">
          <template #default="scope">
            <el-form-item label-width="0px">
              <el-input
                v-model="scope.row.sort"
                placeholder="请输入排序"
                type="text"
                clearable
                @input="settingValue($event, 'sort')"
              />
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        :data="form.specsData"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#eef1f6',
          color: '#606266',
          textAlign: 'center',
        }"
      >
        <template v-slot:empty>
          <p>暂无规格</p>
        </template>

        <el-table-column
          v-for="(item, index) in form.specsList"
          :key="index"
          :prop="`specs_${index}`"
          :label="item.name"
          width="200px"
          align="center"
        ></el-table-column>
        <el-table-column
          label="规格图片"
          prop="image"
          width="170"
          align="center"
        >
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.image`"
              :rules="[
                { required: false, message: '请上传图片', trigger: 'change' },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <div class="skuImg">
                <FormImgUpload
                  :url="scope.row.image ? [scope.row.image] : []"
                  @upload="upload($event, scope.$index)"
                  :index="scope.$index"
                  :limit="1"
                  :maxSize="5"
                  disabled
                  accept="image/jpeg,image/png,image/jpg"
                  :multiple="true"
                />
              </div>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*成本价" prop="costPrice" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.costPrice`"
              :rules="[
                { required: true, message: '请输入成本价', trigger: 'blur' },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <el-input
                v-model="scope.row.costPrice"
                placeholder="请输入成本价"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="salaryChange($event, 'costPrice', scope.$index)"
                type="text"
                disabled
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*折扣方式" prop="discountType" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.discountType`"
              label-width="0px"
              :inline-message="true"
              :rules="[
                {
                  required: true,
                  message: '请选择折扣方式',
                  trigger: 'change',
                },
              ]"
            >
              <el-select
                size="small"
                v-model="scope.row.discountType"
                placeholder="请选择折扣方式"
                @change="
                  typeChange($event, 'discountRatio', scope.$index, scope.row)
                "
              >
                <el-option
                  v-for="item in discountList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*折扣率" prop="discountRatio" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.discountRatio`"
              :rules="[
                {
                  required: scope.row.discountType == 1 ? true : false,
                  // message: '请输入折扣率',
                  validator: discountRatioRule,
                  row: scope.row,
                  trigger: 'change',
                },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <el-input
                :disabled="scope.row.discountType == 1 ? false : true"
                v-model="scope.row.discountRatio"
                placeholder="请输入折扣率"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="salaryChange($event, 'discountRatio', scope.$index)"
                @input="
                  salaryInput($event, 'discountRatio', scope.$index, scope.row)
                "
                type="text"
                clearable
                :max="1"
                :min="0.01"
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*吊牌价" prop="originalPrice" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.originalPrice`"
              :rules="[
                // { required: true, message: '请输入原价', trigger: 'blur' },
                {
                  required: true,
                  validator: originalPriceRule,
                  row: scope.row,
                  trigger: 'change',
                },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <el-input
                v-model="scope.row.originalPrice"
                placeholder="请输入原价"
                disabled
                @input="
                  salaryInput($event, 'originalPrice', scope.$index, scope.row)
                "
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="salaryChange($event, 'originalPrice', scope.$index)"
                type="text"
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*售价" prop="salePrice" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.salePrice`"
              :rules="[
                // { required: true, message: '请输入售价', trigger: 'blur' },
                {
                  required: true,
                  validator: salePriceRule,
                  row: scope.row,
                  trigger: 'change',
                },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <el-input
                :disabled="scope.row.discountType == 1 ? false : true"
                v-model="scope.row.salePrice"
                placeholder="请输入售价"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="salaryChange($event, 'salePrice', scope.$index)"
                @input="
                  salaryInput($event, 'salePrice', scope.$index, scope.row)
                "
                type="text"
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="*排序" prop="sort" width="200">
          <template #default="scope">
            <el-form-item
              :prop="`specsData.${scope.$index}.sort`"
              :rules="[
                { required: true, message: '请输入排序', trigger: 'blur' },
              ]"
              label-width="0px"
              :inline-message="true"
            >
              <el-input
                v-model="scope.row.sort"
                placeholder="请输入排序"
                type="text"
                clearable
              />
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import { listSpec, listSpecChild } from "@/api/supplierGoods/goods";
import UploadImage from "@/components/UploadImage";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "createTwo",
  components: {
    UploadImage,
    FormImgUpload,
  },
  data() {
    return {
      settingData: [
        {
          discountType: "",
          discountRatio: "",
          salePrice: "",
          sort: "",
        },
      ],
      discountList: [
        {
          name: "无折扣",
          id: 0,
        },
        {
          name: "折扣价",
          id: 1,
        },
      ],
      supplierId: "",
      specs: [],
      form: {
        specsIds: [], //选中的规格id
        specsList: [], //选中的规格列表
        specsData: [], //规格项数据
      },
      // 备份规格列数组
      specsArrayBackup: [],
      // 规格项自用添加
      params: {
        image: "",
        code: "",
        weight: "",
        volume: "",
        stock: "",
        needIntegral: "",
        salePrice: "",
        originalPrice: "",
        discountRatio: "",
        discountType: "",
        costPrice: "",
        outerSkuCode: "",
      },
    };
  },
  methods: {
    // 批量设置价格
    settingValue(e, key) {
      this.form.specsData.map((val) => {
        if (key === "salePrice") {
          val[key] = e;
          val.discountRatio = (
            (parseFloat(e) > val.originalPrice
              ? val.originalPrice
              : parseFloat(e)) / val.originalPrice
          ).toFixed(2);
          this.settingData[0].discountRatio=''
        }
        
        if (key === "discountRatio") {
          val[key] = e>1?1:e;
          this.settingData[0].discountRatio=e>1?1:e;
          // val.salePrice = Number(
          //   e * val.originalPrice
          //   ).toFixed(2);
          val.salePrice = Math.ceil(
            e * val.originalPrice
            );
            
            this.settingData[0].salePrice=''
        }
        if (key === "discountType") {
          val[key] = e;
           if(val.discountType==0){
            val.discountRatio=1
            val.salePrice = val.originalPrice
            this.settingData[0].salePrice=''
            this.settingData[0].discountRatio=''
           }
        }
      });
    },
    // 获取原价数据
    salaryInput(e, t, i, row) {
      const value = Number(e);
      if (t === "originalPrice" && !row.discountType) {
        this.form.specsData[i]["salePrice"] = parseFloat(value).toFixed(2);
        // 更新备份规格项数据
        this.specsArrayBackup = JSON.parse(JSON.stringify(this.form.specsData));
      } else {
        if (!row.originalPrice) {
          this.$message.warning("请输入原价~");
        } else {
          // 输入了jinxing原价计算
          if (t === "discountRatio") {
            //输入了折扣计算售价
            // this.form.specsData[i]["salePrice"] = (
            //   row.originalPrice *
            //   (parseFloat(value) > 1 ? 1 : parseFloat(value))
            // ).toFixed(2);
            this.form.specsData[i]["salePrice"] = Math.ceil(
              row.originalPrice *
              (parseFloat(value) > 1 ? 1 : parseFloat(value))
            );
          } else if (t === "salePrice") {
            this.form.specsData[i]["discountRatio"] = (
              (parseFloat(value) > row.originalPrice
                ? row.originalPrice
                : parseFloat(value)) / row.originalPrice
            ).toFixed(2);
          }
        }

        if (t === "discountRatio") {
          const max = 1;
          if (value) {
            if (value > max) {
              this.form.specsData[i][t] = max;
              e = max;
            } else {
              this.form.specsData[i][t] = parseFloat(value);
              e = parseFloat(value);
            }
          }
        }

        if (t === "salePrice" && !row.originalPrice) {
          const max = row.originalPrice;
          if (value) {
            if (value > max) {
              this.form.specsData[i][t] = max;
              e = max;
            } else {
              this.form.specsData[i][t] = parseFloat(value);
              e = parseFloat(value);
            }
          }
        }

        if (t === "originalPrice" && row.discountRatio) {
          if (value) {
            this.form.specsData[i]["salePrice"] = Number(
              value * row.discountRatio
            ).toFixed(2);
          }
        }
      }
    },
    async setForm(form) {
      console.log("---", form);
      // this.form.specsIds = form.specsIds;
      this.form.specsData = JSON.parse(JSON.stringify(form.specsData));
      this.form.specsData.map((item, index) => {
        item.goodsSkuId = item.id; //skuId
        if (item.discountRatio == 0) {
          item.salePrice = item.originalPrice;
        } else {
          if (!item.salePrice) {
            item.salePrice = Number(
              item.originalPrice * item.discountRatio
            ).toFixed(2);
          }
        }
      });

      // this.specsArrayBackup = JSON.parse(JSON.stringify(form.specsData));
      // // console.log(form.spec)
      // // 创建数组array,将form.spec数组和this.specs数组通过id对比,如果相同则将this.specs数组中相同的部分添加到array数组中
      // let specArray = [];
      // for (const item1 of form.spec) {
      //   // console.log('item1', item1)
      //   for (const item2 of this.specs) {
      //     // console.log('item2', item2)
      //     if (item1.id === item2.id) {
      //       item2.checkList = item1.childIds;
      //       // console.log('checkedList', checkedList)
      //       item2.checkedList = await this.setCheckedList(
      //         item1.childIds,
      //         item2.children
      //       );
      //       specArray.push(item2);
      //     }
      //   }
      // }
      // // console.log(specArray);
      // this.form.specsList = specArray;
      // const array = await this.onloadCartesianProduct();
      // // console.log('array',array);
      // this.form.specsData = this.cartesianProductOf(array);
      // // console.log('this.form.specsData',this.form.specsData);
      // this.specsArrayBackup.forEach((item1) => {
      //   this.form.specsData.forEach((item2) => {
      //     if (item1.specIds === item2.specIds) {
      //       item2.image = item1.image;
      //       item2.goodsSkuId = item1.id;//skuId
      //       item2.code = item1.code;
      //       item2.weight = item1.weight;
      //       item2.volume = item1.volume;
      //       item2.stock = item1.stock;
      //       item2.costPrice = item1.costPrice;
      //       item2.discountType = item1.discountType;
      //       item2.discountRatio =
      //         item1.discountRatio > 0 ? item1.discountRatio : "";
      //       item2.originalPrice = item1.originalPrice;
      //       item2.salePrice = item1.salePrice;
      //       item2.needIntegral = item1.needIntegral;
      //     }
      //   });
      // });
    },
    setCheckedList(arr1, arr2) {
      // console.log(arr1,arr2);
      return new Promise((resolve) => {
        const arr = arr1.map((item) => {
          return arr2.find((i) => i.id === item);
        });
        resolve(arr);
      });
    },
    async listSpecId(id) {
      // if (this.supplierId !== id) {
      //   this.form.specsIds = [];
      //   this.form.specsList = [];
      //   this.form.specsData = [];
      //   this.specsArrayBackup = [];
      //   this.supplierId = id;
      // }
      await this.listSpec(id);
    },
    async setSupplierId(id) {
      this.supplierId = id;
      await this.listSpec(id);
    },
    // 多规格组合
    onloadCartesianProduct() {
      return new Promise((resolve) => {
        const arr = [];
        this.form.specsList.map((item) => {
          if (item.checkedList.length > 0) {
            arr.push(item.checkedList);
          }
        });
        resolve(arr);
      });
    },
    // 多规格计算
    cartesianProductOf(array) {
      // console.log('array',array)
      const result = array.reduce(
        function (prev, curr) {
          return prev
            .map(function (x) {
              return curr.map(function (y) {
                return x.concat(y);
              });
            })
            .reduce(function (a, b) {
              return a.concat(b);
            }, []);
        },
        [[]]
      );
      // const result2 = new Array(result.length);
      // for (let i = 0; i < result.length; i++) {
      //   const item = {
      //     specIds: '',
      //     specNames: '',
      //     ...this.params
      //   }
      //   for (let j = 0; j < result[i].length; j++) {
      //     if(item.specIds === '') {
      //       item.specIds = result[i][j].id
      //     }else{
      //       item.specIds += ',' + result[i][j].id
      //     }
      //     item[`specs_${j}`] = result[i][j].name;  // 必须 规格项名称
      //
      //     if(item.specNames === '') {
      //       item.specNames = result[i][j].name
      //     }else{
      //       item.specNames += ',' + result[i][j].name
      //     }
      //   }
      //   result2.push(item);
      //   if(i === result.length - 1){
      //     return result2;
      //   }
      // }
      const result2 = result.map((a) => {
        const item = {
          specIds: "",
          name: "",
          ...this.params,
        };
        a.map((b, bi) => {
          if (item.specIds === "") {
            item.specIds = b.id;
          } else {
            item.specIds += "," + b.id;
          }
          item[`specs_${bi}`] = b.name; // 必须 规格项名称

          if (item.name === "") {
            item.name = b.name;
          } else {
            item.name += "," + b.name;
          }
        });
        return item;
      });
      return result2;
    },
    // 获取规格列表
    async listSpec(id) {
      const { data } = await listSpec();
      for (const item of data) {
        item.checkList = [];
        item.checkedList = [];
        item.children = await this.listSpecChild(item.id);
      }
      console.log("data", data);
      this.specs = data || [];
    },
    // 获取子规格列表
    listSpecChild(e) {
      return new Promise((resolve, reject) => {
        listSpecChild(e)
          .then(({ data }) => {
            const newSpecSon = data.map((item) => {
              const { ...rest } = item;
              return {
                ...rest,
              };
            });
            resolve(newSpecSon);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    // 选中规格
    async changeSpecs(e) {
      // console.log(e);
      this.form.specsList = e.map((item) => {
        return this.specs.find((i) => i.id === item);
      });
      console.log(this.form.specsList);
      await this.updateSpecsData();
    },
    // 选中子规格
    async checked(e, index, indexC) {
      if (e) {
        this.form.specsList[index].checkedList.push({
          id: this.form.specsList[index].children[indexC].id,
          name: this.form.specsList[index].children[indexC].name,
        });
        await this.updateSpecsData();
      } else {
        this.form.specsList[index].checkedList.map((item, i) => {
          if (item.id === this.form.specsList[index].children[indexC].id) {
            this.form.specsList[index].checkedList.splice(i, 1);
          }
        });
        await this.updateSpecsData();
      }
    },
    // 上传图片
    upload(e, index) {
      console.log(e, index, "0000000000000");
      this.form["specsData"][index].image = e.length ? e : "";
      this.$refs.form.clearValidate(`specsData.${index}.image`);
      // // 更新备份规格项数据
      this.specsArrayBackup = JSON.parse(JSON.stringify(this.form.specsData));
    },
    // 删除选中规格
    handleClose(index) {
      this.specs[index].checkList = [];
      this.specs[index].checkedList = [];
      this.form.specsList.splice(index, 1);
      this.form.specsIds = this.form.specsList.map((item) => item.id);
      this.updateSpecsData();
    },
    // 将最新规格项数据和备份规格数据对比,如果有相同的ids,则更新最新规格项数据
    async updateSpecsData() {
      const array = await this.onloadCartesianProduct();
      if (array.length > 0) {
        this.form.specsData = await this.cartesianProductOf(array);
        console.log(this.form.specsData);
        console.log(this.specsArrayBackup);
        // 将两个数组this.form.specsData和this.specsArrayBackup,如果数组中存在相同的ids,则更新this.form.specsData数据
        this.form.specsData.map((item, i) => {
          this.specsArrayBackup.map((itemBackup, iBackup) => {
            if (item.specIds === itemBackup.specIds) {
              Object.keys(itemBackup).map((a) => {
                this.form.specsData[i][a] = itemBackup[a];
              });
            }
          });
        });
      } else {
        this.form.specsData = [];
      }
    },
    typeChange(e, t, i, r) {
      if (e === 0) {
        this.form.specsData[i]["discountRatio"] = "";
        this.form.specsData[i].salePrice = this.form.specsData[i].originalPrice;
      }
      // 更新备份规格项数据
      this.specsArrayBackup = JSON.parse(JSON.stringify(this.form.specsData));
    },
    salaryChange(e, t, i) {
      if (!e.target.value) return;
      const value = e.target.value;
      const max =
        t === "salePrice"
          ? e.target.value > Number(this.form.specsData[i]["originalPrice"])
            ? Number(this.form.specsData[i]["originalPrice"])
            : 999999
          : 999999;
      if (value) {
        if (isNaN(value * 1)) {
          this.form.specsData[i][t] = "";
          e.target.value = "";
        } else {
          if (t === "stock") {
            if (value > max) {
              this.form.specsData[i][t] = max;
              e.target.value = max;
            } else {
              this.form.specsData[i][t] = value * 1;
              e.target.value = value * 1;
            }
          } else {
            if (value > max) {
              this.form.specsData[i][t] = max.toFixed(2);
              if (
                t === "originalPrice" &&
                this.form.specsData[i]["discountType"] === 0
              ) {
                this.form.specsData[i]["salePrice"] = max.toFixed(2);
              }

              e.target.value = max.toFixed(2);
            } else {
              this.form.specsData[i][t] = parseFloat(value).toFixed(2);
              e.target.value = parseFloat(value).toFixed(2);
              if (
                t === "originalPrice" &&
                this.form.specsData[i]["discountType"] === 0
              ) {
                this.form.specsData[i]["salePrice"] =
                  parseFloat(value).toFixed(2);
              }
            }
          }
          // 更新备份规格项数据
          this.specsArrayBackup = JSON.parse(
            JSON.stringify(this.form.specsData)
          );
        }
      } else {
        this.form.specsData[i][t] = "";
      }
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(this.form);
          } else {
            resolve(false);
          }
        });
      });
    },
    changeSkuCode(e, t, i) {
      const pattern = "^[0-9a-zA-z]{0,30}$";
      const regExp = new RegExp(pattern);
      if (e.match(regExp)) {
        this.form.specsData[i][t] = e;
      } else {
        this.form.specsData[i][t] = "";
        e = "";
      }
    },
    discountRatioRule(rule, value, callback) {
      if (value < 0.01 && rule.row.discountType == 1) {
        callback(new Error("折扣率最小0.01"));
      } else {
        callback();
      }
    },
    originalPriceRule(rule, value, callback) {
      console.log("value", value);
      console.log("costPrice", rule.row.costPrice);
      if (value < rule.row.costPrice) {
        callback(new Error("原价必须大于成本价"));
      } else {
        callback();
      }
    },
    salePriceRule(rule, value, callback) {
      if (value < rule.row.costPrice) {
        callback(new Error("售价必须大于成本价"));
      } else if (value > rule.row.originalPrice) {
        callback(new Error("售价必须小于吊牌价"));
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-table .el-form-item {
  margin-bottom: 0;
}
.specsIds /deep/.el-select {
  width: 500px;
}
</style>
