<template>
  <div>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
    >
    </avue-form>
    <div style="margin-left: 134px; margin-top: -10px">
      <el-form label-width="200">
        <el-form-item label-width="200" label="关联商品:">
          <div style="display: flex">
            <el-button
              size="mini"
              type="primary"
              style="min-width: 120px; margin-right: 20px"
              @click="openDio"
              >关联商品</el-button
            >
            <div class="tipImg">最多关联10个商品</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="1000px">
      <ChangeLineGoods @getGoodsItem="getGoodsItem" v-if="dialogVisible" />
    </el-dialog>

    <el-table :data="linkGoods" size="small">
      <el-table-column key="2" type="selection" width="55" />
      <el-table-column label="商品图" min-width="70">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.mainImg"
              :preview-src-list="[scope.row.mainImg]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="商品名称" min-width="200">
        <template slot-scope="scope">
          <div>
            <span
              class="tags_name"
              :style="
                scope.row.specType == 0 ? 'color: #ff8a4d;' : 'color: #4073FA;'
              "
              :class="'name' + scope.row.spec_type"
              >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
            >{{ scope.row.name || "-" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
      <el-table-column prop="stock" label="库存" min-width="70" />
      <el-table-column prop="sort" label="排序" min-width="60" />
      <el-table-column prop="salePrice" label="商品售价" min-width="80" />
      <el-table-column label="操作" fixed="right" width="180px">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            icon="el-icon-info"
            @click="del(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import {listClassify, listSupplier} from "@/api/shop/goods";
import { goodsDeptTree } from "@/api/goods/goods";
import { goodsClassifyStoreList } from "@/api/store/goodsClassify";
import ChangeLineGoods from "./changeLineGoods";
export default {
  name: "createOne",
  components: {
    ChangeLineGoods,
  },
  data() {
    return {
      dialogVisible: false,
      linkGoods: [],
      form: {
        status: 1,
        relevanceIdList: [],
      },
      goodsClassifyList: [], //分组列表
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: [
          {
            label: "商品分类",
            prop: "classifyIds",
            type: "cascader",
            multiple: true,
            span: 12,
            row: true,
            dicData: [],
            props: {
              label: "name",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择商品分组",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择商品分组"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "虚拟销量",
            prop: "saleNum",
            maxlength: 5,
            showWordLimit: true,
            type: "number",
            span: 12,
            row: true,
            rules: [
              {
                required: false,
                message: "最低限购数量",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            maxlength: 5,
            showWordLimit: true,
            type: "number",
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上下架状态",
            prop: "status",
            type: "select",
            dicData: [
              { id: 0, name: "下架" },
              { id: 1, name: "上架" },
            ],
            props: {
              label: "name",
              value: "id",
            },
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    linkGoods: {
      handler(newValue, oldValue) {
        console.log("newValue", newValue);
        this.form.relevanceIdList = newValue.map((ele) => ele.id);
      },
      immediate: true, // 可选，初始渲染时即调用handler
      deep: true, // 开启深度监听，监测数组内部元素的变化
    },
  },
  created() {
    this.getGoodsClassify();
  },
  async mounted() {
    this.$emit("onload");
  },
  methods: {
    openDio() {
      this.dialogVisible = true;
    },
    del(index) {
      this.linkGoods.splice(index, 1);
    },
    getGoodsItem(row) {
      if (row.length + this.linkGoods.length > 10) {
        this.$message.error("最多只能选择10个商品");
        return;
      }
      this.linkGoods = this.linkGoods.concat(row);
      this.linkGoods = this.uniqueByStringify(
        JSON.parse(JSON.stringify(this.linkGoods))
      );
      this.dialogVisible = false;
    },
    uniqueByStringify(arr) {
      const uniqueSet = new Set();
      const result = [];
      arr.forEach((item) => {
        const strItem = JSON.stringify(item);
        if (!uniqueSet.has(strItem)) {
          uniqueSet.add(strItem);
          result.push(JSON.parse(strItem));
        }
      });
      return result;
    },

    minNum() {
      return false;
    },
    async getGoodsClassify() {
      const { data } = await goodsClassifyStoreList();

      let l = data.filter((item) => {
        item.children =
          item.children &&
          item.children.filter((val) => {
            return val.children;
          });
        return item.children && item.children.length > 0;
      });

      // this.formOption.column[0].dicData = l;
      const obj = this.findObject(this.formOption.column, "classifyIds");
      obj.dicData = l || [];
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.mainImg = [...e];
      } else {
        this.form.mainImg = [...this.form.mainImg, e];
      }
    },
    //图片上传
    upload1(e) {
      if (Array.isArray(e)) {
        this.form.logoImg = [...e];
      } else {
        this.form.logoImg = [...this.form.logoImg, e];
      }
    },
    //图片上传
    upload2(e) {
      if (Array.isArray(e)) {
        this.form.bannerImg = [...e];
      } else {
        this.form.bannerImg = [...this.form.bannerImg, e];
      }
    },

    setForm(form) {
      console.log(form, "ffffff");
      this.form = form;
      if (form.relevanceList) {
        this.linkGoods = JSON.parse(JSON.stringify(form.relevanceList));
      }

      console.log(this.form, "this.formthis.form");
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done();
            resolve(this.form);
          } else {
            done();
            resolve(false);
          }
        });
      });
    },
    uploadDelete(file, column) {
      // return this.$confirm(`是否确定移除？`, "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   beforeClose: (action, instance, done) => {
      //     // console.log(action)
      //     if (action === "confirm") {
      //       let array = JSON.parse(
      //         JSON.stringify(this.form[column.prop].split(","))
      //       );
      //       let index = array.findIndex((item) => item === file.url);
      //       array.splice(index, 1);
      //       column.propsHttp.name = array.toString();
      //       if (array.length < column.limit) {
      //         this.upload("inline-block", column.prop);
      //       }
      //     }
      //     done();
      //   },
      // });
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      // console.log(column)
      // if (!res.path) {
      //   this.$message.error("上传失败");
      //   loading();
      // } else {
      //   if (column.prop === "banner") {
      //     if (column.propsHttp.name) {
      //       column.propsHttp.name += "," + res.path;
      //     } else {
      //       column.propsHttp.name = res.path;
      //     }
      //   } else {
      //     column.propsHttp.name = res.path;
      //   }
      //   console.log(column.propsHttp.name.split(",").length);
      //   if (column.propsHttp.name.split(",").length >= column.limit) {
      //     console.log(column.propsHttp.name.split(",").length);
      //     console.log(column.limit);
      //     this.upload("none");
      //   }
      // }
    },
    // upload(type) {
    //   let upload = document.getElementsByClassName("el-upload")[2];
    //   upload.style.display = type === "none" ? "none" : "inline-block";
    // },
  },
};
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
</style>
